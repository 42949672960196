import { IExtRouteConfig } from '_utils/routing'
import { autoinject } from 'aurelia-framework'
import { PLATFORM } from 'aurelia-pal'
import { Router, RouterConfiguration } from 'aurelia-router'
import { LocaleService } from 'services/locale-service'

/**
 * This class specifies all routes related to facilities.
 */
@autoinject
export class Devices {
  public router: Router
  constructor(private readonly localServ:LocaleService){}
  public async configureRouter(config: RouterConfiguration, router: Router) {
    await this.localServ.loadLanguage()
    const routes: IExtRouteConfig[] = [
      {
        route: ['', 'device-list'],
        name: 'device-list',
        moduleId: PLATFORM.moduleName('devices/device-list'),
        title: this.localServ.translate('pages.device-overview'),
        settings: {
          b: true,
          requiresAuthentication: true
        }
      },
      {
        route: ':imsi',
        name: 'luminaire-details',
        moduleId: PLATFORM.moduleName('devices/luminaire-details'),
        title: this.localServ.translate('pages.lum-details'),
        settings: {
          b: true,
          requiresAuthentication: true
        }
      },
      {
        route: 'device-add',
        name: 'device-add',
        moduleId: PLATFORM.moduleName('devices/device-add'),
        title: this.localServ.translate('pages.add-device'),
        settings: {
          b: true,
          requiresAuthentication: true
        }
      },
      {
        route: 'fms/:imsi',
        name: 'fms-details',
        moduleId: PLATFORM.moduleName('devices/fms-details'),
        title: this.localServ.translate('pages.fms-details'),
        settings: {
          b: true,
          requiresAuthentication: true
        }
      },
      {
        route: 'cps/:imsi',
        name: 'cps-details',
        moduleId: PLATFORM.moduleName('devices/cps-details'),
        title: this.localServ.translate('pages.cps-details'),
        settings: {
          b: true,
          requiresAuthentication: true
        }
      },
      {
        route: 'cps/:imsi/config',
        name: 'cps-config',
        moduleId: PLATFORM.moduleName('devices/cps-config'),
        title: this.localServ.translate('pages.cps-config'),

        settings: {
          b: true,
          requiresAuthentication: true
        }
      },
      {
        route: 'gateway/:imsi',
        name: 'gateway-details',
        moduleId: PLATFORM.moduleName('devices/gateway-details'),
        title: this.localServ.translate('pages.gateway-details'),
        settings: {
          b: true,
          requiresAuthentication: true
        }
      },
      {
        route: 'gateway/:imsi/replace',
        name: 'gateway-replace',
        moduleId: PLATFORM.moduleName('devices/gateway-details/gateway-replace'),
        title: this.localServ.translate('pages.gateway-details'),
        settings: {
          b: true,
          requiresAuthentication: true
        }
      }
    ]

    config.map(routes)

    this.router = router
  }
}
