import { subscriberMixin } from '@nubix/npm-utils/src/aurelia/subscriberMixin'
import { Cps, Facility } from '@nubix/spica-cloud-backend-client'
import { doAfterNavigation } from '_utils/app-history/utils'
import { SseHandler } from '_utils/sseHandler'
import { HtmlValidationRenderer } from '_utils/validation/htmlValidationRenderer'
import { computedFrom } from 'aurelia-binding'

import { autoinject } from 'aurelia-dependency-injection'
import { getLogger } from 'aurelia-logging'
import { Router } from 'aurelia-router'
import {
  ValidationController,
  ValidationControllerFactory,
  ValidationRules
} from 'aurelia-validation'
import * as _ from 'lodash'
import { cpCpsStatic, cpCpsStatus } from 'model/utils'
import { first } from 'rxjs/operators'
import { AuthService } from 'services/auth-service'
import { CpsService } from 'services/cps-service'
import { FacilityService } from 'services/facility-service'
import { getPermissionTable } from 'spica-cloud-shared/lib/model/permissions'
import tippy, { hideAll } from 'tippy.js'
import { findNavigationToRoute } from '../_utils/routing'
import { reportErr } from '../errorReporting'
import { Device } from '../model/device'
import { LocaleService } from '../services/locale-service'

const LOG = getLogger('cps-details')

export interface ICpsDetailsParams {
  /**
   * Imsi of the cps to display
   */
  imsi?: string
}

/**
 * A route to display and edit information of an CPS
 */
@autoinject()
export class CpsDetails extends subscriberMixin() {
  public cachedCps: Cps
  public cachedFacility?: Facility

  public formRoot: HTMLFormElement
  public deleteConfirmationRoot: HTMLElement
  public deleteConfirmationTrigger: HTMLButtonElement

  public cpsDetailsValidationController: ValidationController

  private updateHandler: SseHandler
  private updateHandlerFac: SseHandler
  private archivedCps: Cps

  @computedFrom('cachedFacility')
  get may() {
    return getPermissionTable(this.cachedFacility?.myRole)
  }

  constructor(
    private readonly router: Router,
    private readonly authServ: AuthService,
    private readonly _facilityService: FacilityService,
    private readonly _cpsService: CpsService,
    private readonly locale: LocaleService,
    private readonly validationFactory: ValidationControllerFactory
  ) {
    super()
    this.configureValidator()
  }

  @computedFrom('cachedCps.name', 'cachedCps.installationDate', 'archivedCps')
  public get dirty(): boolean {
    if (!this.cachedCps || !this.archivedCps) return false

    return !(
      this.cachedCps.name === this.archivedCps.name &&
      this.cachedCps.installationDate === this.archivedCps.installationDate
    )
  }

  @computedFrom('cachedCps')
  get device(): Device {
    return { deviceType: 'cps', ...this.cachedCps }
  }

  /**
   * On activation, load and display information from the server and start autorefresh.
   * @param params - Parameters to be passed via the url of the page. See {@link ICpsDetailsParams}
   */
  public async activate(params: ICpsDetailsParams) {
    if (params.imsi === undefined) return doAfterNavigation(this.router, () => this.navigateUp())

    const cached = await this._cpsService.getEntityByImsi(params.imsi)

    if (!cached) {
      return doAfterNavigation(this.router, () => this.navigateUp())
    }

    const cps$ = this._cpsService.cpsEntityCache.get$(cached.id)

    this.subscribeUntilDeactivated({
      to: cps$,
      onNext: async (it) => {
        if (it === undefined) {
          return doAfterNavigation(this.router, () => this.navigateUp())
        }
        const cps = it
        // If the Luminaire isn't owned by anyone, open the Add-Dialog
        // This should be done earlier in the Lifecycle, so we don't have to load this route first
        if (!cps.companyId) {
          findNavigationToRoute(this.router, 'device-add', { imsi: cps.imsi, deviceType: 'cps' })

          return
        }
        this.dirty ? this.updateNonEdit(cps) : this.updateFull(cps)

        if (this.cachedCps.location.facilityId === 0) {
          this.cachedFacility = undefined
        } else if (
          !this.cachedFacility ||
          this.cachedFacility.id !== this.cachedCps.location.facilityId
        ) {
          this._facilityService.facilityEntityCache
            .get(this.cachedCps.location.facilityId)
            .then((it) => (this.cachedFacility = it))
        }

        // start event tracking
        if (!this.updateHandler) {
          this.autorefreshStatusCps(cps)
        }
        if (!this.updateHandlerFac) {
          this.autorefreshDataFacility(cps.location.facilityId)
        }
      }
    })

    // wait for the first result
    await cps$.pipe(first()).toPromise()
  }

  /**
   * After the Dom is loaded, create a dropdown to confirm cps removal.
   */
  public attached() {
    tippy(this.deleteConfirmationTrigger, {
      trigger: 'click',
      content: this.deleteConfirmationRoot,
      interactive: true,
      placement: 'bottom',
      animation: 'shift-away'
    })
  }

  /**
   * On deactivation, close SSE connection to server
   */
  public override deactivate() {
    super.deactivate()

    if (this.updateHandler) {
      this.updateHandler.close()
    }
    if (this.updateHandlerFac) {
      this.updateHandlerFac.close()
    }
  }

  public async configureValidator() {
    this.cpsDetailsValidationController = this.validationFactory.createForCurrentScope()
    this.cpsDetailsValidationController.addRenderer(new HtmlValidationRenderer())
  }

  /**
   * Validate the form and submit changes to the server
   */
  public async onSave() {
    this.cpsDetailsValidationController.reset()
    ValidationRules.ensure('name')
      .displayName('Name')
      .maxLength(255)
      .withMessage('Maximal 255 Zeichen')
      .on(this.cachedCps)

    const validationResult = await this.cpsDetailsValidationController.validate()

    if (!validationResult.valid) {
      this.formRoot.reportValidity()
      throw new Error()
    }

    // send changed data to backend
    await this._cpsService.updateEntity(this.cachedCps).catch(async () => {
      throw new Error(
        'Die Daten konnten nicht gespeichert werden. Bitte überprüfen Sie Ihre Eingaben.'
      )
    })

    // reset dirty state / all temporary changes are lost
    this.updateFull(this.cachedCps)
  }

  /**
   * Submit a deletion request for the current cps to the server
   */
  public async onDelete() {
    try {
      await this._cpsService.removeEntity(this.cachedCps.id)
      this.navigateUp()
    } catch (e) {
      throw new Error(this.locale.translate('cps.delete.failed'))
    }
  }

  /**
   * Navigate to the device list of the parent facility for this cps
   */
  public async navigateUp(overrideFacilityId?: number) {
    const facilityId = this.cachedFacility
      ? this.cachedFacility.id
      : overrideFacilityId !== undefined
      ? overrideFacilityId
      : undefined

    if (facilityId !== undefined) {
      await findNavigationToRoute(this.router, 'device-list', { id: facilityId })
    } else {
      await findNavigationToRoute(this.router, 'facilities')
    }
  }

  public onCancel() {
    hideAll()
  }

  public onConfig() {
    findNavigationToRoute(this.router, 'cps-config', { imsi: this.cachedCps.imsi })
  }

  @computedFrom('cachedCps.lastMessage.typeCode')
  public get displayedMessage() {
    if (!this.cachedCps || !this.cachedCps.lastMessage) return ''

    const code = this.cachedCps.lastMessage.typeCode
    if (code) {
      return code
    }

    return ''
  }

  /**
   * Register a SSE connection with the server and handle status updates
   */
  private autorefreshStatusCps(cps: Cps) {

       
    LOG.debug('start event tracking')

    const revalidateCache = _.debounce(() => {
      this._cpsService.cpsEntityCache.revalidate(cps.id)
      this._cpsService.cpsFacilityCache.invalidate(cps.location.facilityId)
    }, 200)

    const fullRevalidation = async () => {
      // reset dirty state / all temporary changes are lost
      this.updateNonEdit(this.cachedCps)
      revalidateCache()
    }

    const listener4Del = (event: any) => {
      if (event.type === 'deletedcps') {
        // revalidate cache since entity was deleted
        revalidateCache()
        // invalidate device list
        this._cpsService.cpsFacilityCache.invalidate(cps.location.facilityId)
        // leave page
        this.navigateUp().catch((e) => reportErr(e))
      }
    }

    this.updateHandler = new SseHandler(`/events/cps/${cps.id}`, this.authServ)
    this.updateHandler.addEventListener('deletedcps', listener4Del)
    this.updateHandler.addEventListener('statuscps', revalidateCache)
    this.updateHandler.addEventListener('changecps', revalidateCache)
    this.updateHandler.addEventListener('datacps', fullRevalidation)
    this.updateHandler.onReconnect(fullRevalidation)
    this.updateHandler.connectStream()
  }

  /**
   * Register an SSE connection with the server and handle all updates related to a
   */
  private async autorefreshDataFacility(facilityId: number) {
    const listener4Del = (event: any) => {
      if (event.type === 'deletedfacility') {
        this.navigateUp()
      }
    }
    const revalidateCache = _.debounce(() => {
      this._facilityService.facilityEntityCache.invalidate(facilityId)
    }, 200)

    this.updateHandlerFac = new SseHandler(`/events/facility/${facilityId}`, this.authServ)
    this.updateHandlerFac.addEventListener('deletedfacility', listener4Del)
    this.updateHandlerFac.addEventListener('statusfacility', revalidateCache)
    this.updateHandlerFac.addEventListener('changefms', revalidateCache)
    this.updateHandlerFac.addEventListener('datafacility', revalidateCache)
    this.updateHandlerFac.onReconnect(revalidateCache)
    this.updateHandlerFac.connectStream()
  }

  /**
   * Update all shown information with data from backend. Might trigger a visible refresh of the page. Will
   * refresh data in editable input fields too. Will reset dirty state.
   */
  private async updateFull(update?: Cps) {
    if (!update) return

    this.cachedCps = JSON.parse(JSON.stringify(update))
    // we have to copy it
    this.archivedCps = update
  }

  /**
   * Load data from the server and update the state and information only. Does not update data that is
   * stored in editable input fields on the page. This is done to prevent that the user edit some information and an
   * update triggered by an event is overriding the change before it could be stored in the DB. By updating the
   * viewmodel this update should not trigger a visible refresh of the page or parts of it.
   */
  private async updateNonEdit(update?: Cps) {
    if (!this.cachedCps) return
    if (!update) return

    // state too
    cpCpsStatus(this.cachedCps, update)
    // non editable infos
    cpCpsStatic(this.cachedCps, update)
  }
}
