import { IExtRouteConfig } from '_utils/routing'
import { autoinject } from 'aurelia-framework'
import { PLATFORM } from 'aurelia-pal'
import { activationStrategy, Router, RouterConfiguration } from 'aurelia-router'
import { LocaleService } from 'services/locale-service'

/**
 * This class specifies all routes related to the signup process.
 * The user will navigate from
 */
@autoinject
export class App {
  public router: Router

  constructor(private readonly localServ:LocaleService){}
  // tslint:disable-next-line:max-func-body-length
  public async configureRouter(config: RouterConfiguration, router: Router) {
    await this.localServ.loadLanguage()
    const routes: IExtRouteConfig[] = [
      { route: '', redirect: 'start' },
      {
        route: 'start',
        name: 'start',
        moduleId: PLATFORM.moduleName('signup/registration'),
        title: this.localServ.translate('pages.signup'),
        activationStrategy: activationStrategy.invokeLifecycle,
        settings: {
          b: true,
          d: true
        }
      },
      {
        route: 'success-notification',
        name: 'success-notification',
        moduleId: PLATFORM.moduleName('signup/success-notification'),
        title: this.localServ.translate('pages.confirmation'),
        settings: {
          b: true,
          d: true
        }
      },
      {
        route: 'confirm-email',
        name: 'confirm-email',
        moduleId: PLATFORM.moduleName('signup/confirm-email'),
        title: this.localServ.translate('pages.email-confirm'),

        settings: {
          b: true,
          d: true
        }
      },
      {
        route: 'confirm/:id',
        name: 'confirm',
        moduleId: PLATFORM.moduleName('signup/confirm'),
        title:  this.localServ.translate('pages.signup-running'),
        settings: {
          b: false,
          d: true
        }
      },
      {
        route: 'confirm-failure',
        name: 'confirm-failure',
        moduleId: PLATFORM.moduleName('signup/confirm-failure'),
        title: this.localServ.translate('pages.signup-failed'),
        settings: {
          b: false,
          d: true
        }
      },
      {
        route: 'confirm-success',
        name: 'confirm-success',
        moduleId: PLATFORM.moduleName('signup/confirm-success'),
        title: this.localServ.translate('pages.signup-success'),
        settings: {
          b: false,
          d: true
        }
      },
      {
        route: 'reset-password',
        name: 'reset-password',
        moduleId: PLATFORM.moduleName('signup/reset-password'),
        title: this.localServ.translate('pages.reset-password'),
        settings: {
          b: true,
          d: true
        }
      },
      {
        route: 'undo/:id',
        name: 'undo',
        moduleId: PLATFORM.moduleName('signup/undo'),
        title: this.localServ.translate('pages.reset-contact'),
        settings: {
          b: false,
          d: true
        }
      }
    ]

    config.map(routes)

    this.router = router
  }
}
